<template>
  <div>
    <v-row>
      <v-col md="6">
        <encrypted-input
          v-model="model.username"
          label="User name*"
          data-cy="username"
          :rules="rules.username"
          :readonly="readonly"
          :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
        ></encrypted-input>
      </v-col>
      <v-col md="6">
        <encrypted-input
          v-model="model.password"
          label="Password*"
          data-cy="password"
          :rules="rules.password"
          :readonly="readonly"
          :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
        ></encrypted-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <encrypted-input
          v-model="model.clientId"
          label="Client Id"
          data-cy="clientid"
          :readonly="readonly"
          :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
        ></encrypted-input>
      </v-col>
      <v-col md="6">
        <encrypted-input
          v-model="model.clientSecret"
          label="Client Secret"
          data-cy="clientsecret"
          :readonly="readonly"
          :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
        ></encrypted-input>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { BearerCredentialsDto } from "@/datahub-api";
import { Validators } from "@/helpers";
@Component
export default class BearerTokenAuthenticationComponent extends Vue {
  @Prop() model: BearerCredentialsDto;
  @Prop({ default: false }) readonly: boolean;
  @Prop() alwaysShowEncryptedValue: boolean;

  rules = {
    username: Validators.Required.Text,
    password: Validators.Required.Text,
  };
}
</script>
