<template>
  <v-row>
    <v-col md="4">
      <v-text-field
        type="text"
        v-model="model.headerName"
        label="Header name"
        data-cy="header"
        :rules="rules.headerName"
        :readonly="readonly"
      ></v-text-field>
    </v-col>
    <v-col md="4">
      <encrypted-input
        v-model="model.key"
        label="Api Key"
        data-cy="apikey"
        :rules="rules.apiKey"
        :readonly="readonly"
        :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
      >
      </encrypted-input>
    </v-col>
    <v-col md="4">
      <v-text-field
        v-model="model.schema"
        label="schema"
        data-cy="schema"
        :readonly="readonly"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { Validators } from "@/helpers";

export default {
  name: "apikeyAuth",
  data() {
    return {
      rules: {
        headerName: Validators.Required.Text,
        apiKey: Validators.Required.Text,
      },
    };
  },
  props: {
    model: {},
    readonly: Boolean,
    alwaysShowEncryptedValue: Boolean,
  },
};
</script>
