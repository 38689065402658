<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-select
          :items="$lookupService.contentTypes"
          v-model="model.configuration.contentType"
          label="Content type"
          data-cy="content-type"
          @change="syncMonacoContent()"
          hint="The 'Content-Type' header that represents the type of content in the request body"
          persistent-hint
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-show="!isFormUrlEncoded">
          <div id="editor"></div>
          <v-btn
            color="secondary"
            style="margin-top: 10px; float: right"
            @click="formatCode()"
            outlined
            rounded
            small
            >Reformat & beautify code</v-btn
          >
        </div>
        <formUrlEncoded :model="model" v-if="isFormUrlEncoded" />
      </v-col>
    </v-row>
  </div>
</template>

<style>
#editor {
  min-height: 300px;
  width: 100%;
  resize: vertical;
  overflow-y: hidden;
}
</style>

<script>
import formUrlEncoded from "@/views/datasource/request/formUrlEncoded";
import constants from "@/services/constants";
import * as monaco from "monaco-editor";
import * as xmlFormatter from "xml-formatter";

export default {
  props: {
    model: {},
  },
  components: {
    formUrlEncoded,
  },
  computed: {
    isFormUrlEncoded() {
      return (
        this.model.configuration.contentType === constants.contentTypes.formUrlEncoded
      );
    },
    monacoLanguage() {
      if (this.model.configuration.contentType === constants.contentTypes.json) {
        return "json";
      }
      if (
        this.model.configuration.contentType === constants.contentTypes.xml ||
        this.model.configuration.contentType === constants.contentTypes.soapxml ||
        this.model.configuration.contentType === constants.contentTypes.textxml
      ) {
        return "xml";
      }

      return "plaintext";
    },
  },
  data: function () {
    return {
      monacoEditor: null,
      panelModel: [],
    };
  },
  mounted: function () {
    this.monacoEditor = monaco.editor.create(document.getElementById("editor"), {
      value: this.model.requestBody,
      language: this.monacoLanguage,
      minimap: { enabled: true },
      scrollBeyondLastLine: false,
      automaticLayout: true,
      theme: "vs-dark",
    });
    this.syncMonacoContent();
  },
  watch: {
    model: function () {
      this.syncMonacoContent();
    },
  },
  methods: {
    formatCode() {
      if (this.monacoLanguage === "xml") {
        this.monacoEditor
          .getModel()
          .setValue(xmlFormatter(this.model.requestBody, { collapseContent: true }));
        return;
      }
      this.monacoEditor.getAction("editor.action.formatDocument").run();
    },
    syncMonacoContent() {
      const model = monaco.editor.createModel(
        this.model.requestBody,
        this.monacoLanguage
      );

      model.onDidChangeContent(() => {
        this.model.requestBody = this.monacoEditor.getValue();
      });

      this.monacoEditor.setModel(model);
    },
  },
};
</script>
