<template>
  <v-row>
    <v-col md="6">
      <encrypted-input
        v-model="model.username"
        :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
        label="Username"
        data-cy="username"
        :rules="rules.username"
        :readonly="readonly"
      ></encrypted-input>
    </v-col>
    <v-col md="6">
      <encrypted-input
        v-model="model.password"
        :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
        label="Password"
        data-cy="password"
        :rules="rules.password"
        :readonly="readonly"
      ></encrypted-input>
    </v-col>
  </v-row>
</template>

<script>
import { Validators } from "@/helpers";

export default {
  name: "basicAuth",
  data() {
    return {
      rules: {
        username: Validators.Required.Text,
        password: Validators.Required.Text,
      },
    };
  },
  props: {
    model: {},
    readonly: Boolean,
    alwaysShowEncryptedValue: Boolean,
  },
};
</script>
