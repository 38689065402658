<template>
  <v-simple-table data-cy="additional-fields-component">
    <template v-slot:default>
      <thead>
        <tr>
          <td>Key</td>
          <td>Value</td>
          <td>
            <tooltip message="Add" v-if="$permissions.canModifyConnection && !readonly">
              <v-icon data-cy="addEmpty" @click="addEmpty()">mdi-plus</v-icon>
            </tooltip>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in keyValues" :key="i">
          <td>
            <v-text-field
              v-model="item.key"
              label="Key"
              data-cy="key"
              :rules="rules.key"
              @input="onChange"
              :readonly="readonly"
            ></v-text-field>
          </td>
          <td>
            <encrypted-input
              v-model="item.value"
              label="Value"
              data-cy="value"
              :rules="rules.value"
              @input="onChange"
              :readonly="readonly"
              :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
            ></encrypted-input>
          </td>
          <td>
            <tooltip
              message="Remove"
              v-if="$permissions.canModifyConnection && !readonly"
            >
              <friss-icon @click="remove(i)" name="delete"></friss-icon>
            </tooltip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script lang="ts">
import Tooltip from "@/components/tooltip.vue";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Validators } from "@/helpers";

@Component({
  components: {
    Tooltip,
  },
})
export default class AdditionalFieldsComponent extends Vue {
  @Prop() model: { [key: string]: string };
  @Prop({ default: false }) readonly: boolean;
  @Prop({ default: false }) alwaysShowEncryptedValue: boolean;

  keyValues: Array<{ key: string; value: string }> = [];

  rules = {
    key: Validators.Required.Text,
    value: Validators.Required.Text,
  };

  created() {
    if (!this.model) {
      this.addEmpty();
      return;
    }

    this.updateKeyValues();
  }

  @Watch("model")
  private updateKeyValues() {
    this.keyValues = Object.entries(this.model).map(([key, value]) => ({ key, value }));
  }

  addEmpty() {
    this.keyValues.push({
      key: "",
      value: "",
    });
    this.onChange();
  }
  remove(index: number) {
    this.keyValues.splice(index, 1);
    this.onChange();
  }

  onChange() {
    const dictionary: { [key: string]: string } = {};

    for (const pair of this.keyValues) {
      dictionary[pair.key] = pair.value;
    }

    this.$emit("onChange", dictionary);
  }
}
</script>
