<template>
    <v-card class="mx-auto" outlined>
        <v-app-bar>
            <v-row>
                <v-col md="12">
                    <h2>Invocation Details</h2>
                </v-col>
            </v-row>
        </v-app-bar>
        <v-container fluid fill-height>
            <v-row align="center"
                   justify="center"
                   class="fill-height">
                <v-col md="6" sm="8">
                    <v-form lazy-validation>
                        <v-label for="storage-reference">Storage Reference</v-label>
                        <v-text-field id="storage-reference" v-model="storageReference"
                                      persistent-placeholder
                                      type="text"></v-text-field>
                    </v-form>
                </v-col>
                <v-col sm="4" md="2">
                    <v-card-actions>
                        <v-btn data-cy="search" color="primary" rounded outlined block @click="search">Search</v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-row align="center"
                   justify="center"
                   class="fill-height">
                <v-col md="5" sm="4">
                    <label for="editor">Raw Request</label>
                    <div id="editorRawRequest" class="editor-monaco editor-monaco-request"></div>
                </v-col>
                <v-col md="5" sm="4">
                    <label for="editor">Request Info</label>
                    <div id="editorRequestInfo" class="editor-monaco editor-monaco-request-info"></div>
                </v-col>
            </v-row>
            <v-row align="center"
                   justify="center"
                   class="fill-height">
                <v-col md="10" sm="8">
                    <label for="editorResponse">Response</label>
                    <div id="editorResponse" class="editor-monaco editor-monaco-response"></div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<style>
.editor-monaco {
    min-height: 300px;
    width: 100%;
    resize: vertical;
    overflow-y: hidden;
}
</style>
<script lang="ts">
import * as monaco from "monaco-editor";
import { editor } from "monaco-editor";
import constants from "@/services/constants";
import { API, ResponseType } from "@/datahub-api";
import xmlFormatter from "xml-formatter";
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class InvocationsView extends Vue {
    storageReference = "";
    model = {
        request: "",
        response: "",
    };
    monacoEditorRequest: IStandaloneCodeEditor = null!;
    monacoEditorRequestInfo: IStandaloneCodeEditor = null!;
    monacoEditorResponse: IStandaloneCodeEditor = null!;

    async mounted() {

        const containerRequest = document.querySelector(".editor-monaco-request")! as HTMLElement;
        const containerRequestInfo = document.querySelector(".editor-monaco-request-info")! as HTMLElement;
        const containerResponse = document.querySelector(".editor-monaco-response")! as HTMLElement;

        this.monacoEditorRequest = monaco.editor.create(containerRequest, {
            value: "",
            readOnly: true,
            language: "json",
            minimap: { enabled: true },
            scrollBeyondLastLine: false,
            automaticLayout: true,
            theme: "vs-dark",
        });

        this.monacoEditorRequestInfo = monaco.editor.create(containerRequestInfo, {
            value: "",
            readOnly: true,
            language: "json",
            minimap: { enabled: true },
            scrollBeyondLastLine: false,
            automaticLayout: true,
            theme: "vs-dark",
        });

        this.monacoEditorResponse = monaco.editor.create(containerResponse, {
            value: "",
            readOnly: true,
            language: "json",
            minimap: { enabled: true },
            scrollBeyondLastLine: false,
            automaticLayout: true,
            theme: "vs-dark",
        });
    }

    async search() {
        const response = await API.invokeService.getInvocationDetails(this.storageReference);
        this.monacoEditorRequestInfo.setValue("");
        this.monacoEditorRequest.setValue("");
        this.monacoEditorResponse.setValue("");
        if (response.isSuccess) {
            this.setMonacoLanguage(response.result.responseType);
            this.monacoEditorRequestInfo.setValue(response.result.request ? JSON.stringify(JSON.parse(response.result.request).RequestInfo, null, 2) : "");
            this.monacoEditorRequest.setValue(response.result.request ? JSON.parse(response.result.request).RawRequest : "");
            this.monacoEditorResponse.setValue(response.result.rawResponse ?? "");
            this.formatCode(response.result.responseType);
        }
    }

    setMonacoLanguage(responseType: ResponseType) {
        if (responseType === constants.responseTypes.json) {
            monaco.editor.setModelLanguage(this.monacoEditorRequest.getModel()!, "json");
            monaco.editor.setModelLanguage(this.monacoEditorResponse.getModel()!, "json");
        }
        if (responseType === constants.responseTypes.xml) {
            monaco.editor.setModelLanguage(this.monacoEditorRequest.getModel()!, "xml");
            monaco.editor.setModelLanguage(this.monacoEditorResponse.getModel()!, "xml");
        }
        if (responseType === constants.responseTypes.base64) {
            monaco.editor.setModelLanguage(this.monacoEditorRequest.getModel()!, "base64");
            monaco.editor.setModelLanguage(this.monacoEditorResponse.getModel()!, "base64");
        }
        if (responseType === constants.responseTypes.raw) {
            monaco.editor.setModelLanguage(this.monacoEditorRequest.getModel()!, "raw");
            monaco.editor.setModelLanguage(this.monacoEditorResponse.getModel()!, "raw");
        }
    }

    formatCode(responseType: ResponseType) {

        const editors = [this.monacoEditorRequest, this.monacoEditorResponse];

        if (responseType === ResponseType.Xml) {
            editors.forEach(editor => {
                editor.setValue(xmlFormatter(editor.getValue(), { collapseContent: true }));
            });
        } else if (responseType === ResponseType.Json) {
            editors.forEach(editor => {
                editor.setValue(JSON.stringify(JSON.parse(editor.getValue()), null, 2));
            });
        }
    }
}
</script>
