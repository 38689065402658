import {
  AuthenticationMethod,
  HttpVerb,
  ResponseType,
  SelectMethod,
  SigningMethod,
} from "@/datahub-api";

const constants = {
  authentications: {
    customAuth: -1,
    none: AuthenticationMethod.None,
    basicAuth: AuthenticationMethod.BasicAuth,
    apiKey: AuthenticationMethod.ApiKey,
    certificate: AuthenticationMethod.Certificate,
    bearerToken: AuthenticationMethod.BearerToken,
    customTemplate: AuthenticationMethod.CustomTemplate,
    customToken: AuthenticationMethod.CustomToken,
    infologAuth: AuthenticationMethod.InfologAuth,
    cookieAuth: AuthenticationMethod.CookieAuth,
  },
  signings: {
    none: SigningMethod.None,
    soapMessage: SigningMethod.SoapMessage,
  },
  selectMethods: {
    all: SelectMethod.All,
    first: SelectMethod.First,
    last: SelectMethod.Last,
  },
  grantTypes: {
    clientCredentials: "client_credentials",
    password: "password",
  },
  contentTypes: {
    json: "application/json",
    xml: "application/xml",
    formUrlEncoded: "application/x-www-form-urlencoded",
    soapxml: "application/soap+xml",
    textxml: "text/xml",
  },
  responseTypes: {
    json: ResponseType.Json,
    xml: ResponseType.Xml,
    base64: ResponseType.Base64,
    raw: ResponseType.Raw,
  },
  httpVerbs: {
    post: HttpVerb.Post,
    get: HttpVerb.Get,
  },
  guid: {
    empty: "00000000-0000-0000-0000-000000000000",
  },
  clientValidation: {
    defaultMessage: "Please fill in the required information.",
  },
  friss: "friss",
  statusCodes: {
      Status401Unauthorized: 401,
  }
};

export default constants;
