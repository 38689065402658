<template>
  <v-card class="mb-5">
    <v-card-text>
      <h4>Credentials</h4>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <td>Key</td>
              <td>Value</td>
              <td>Actions</td>
              <td>
                <tooltip message="Add key" v-if="!readonly">
                  <v-icon data-cy="headeradd" @click="add()">mdi-plus</v-icon>
                </tooltip>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in viewModels" :key="i">
              <td>
                <v-text-field
                  data-cy="headername"
                  v-model="item.key"
                  :rules="rules.key"
                  :readonly="readonly"
                ></v-text-field>
              </td>
              <td>
                <encrypted-input
                  data-cy="headervalue"
                  v-model="item.value"
                  :rules="rules.value"
                  :readonly="readonly"
                  :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
                ></encrypted-input>
              </td>
              <td>
                <tooltip message="Delete" v-if="!readonly">
                  <friss-icon
                    data-cy="paramdelete"
                    @click="remove(i)"
                    name="delete"
                  ></friss-icon>
                </tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import tooltip from "@/components/tooltip.vue";
import { Validators } from "@/helpers";

export default {
  name: "cookieAuth",
  components: {
    tooltip,
  },
  props: {
    model: {
      credentials: {},
    },
    readonly: Boolean,
    alwaysShowEncryptedValue: Boolean,
  },
  data: function () {
    return {
      viewModels: Object.entries(this.model.credentials).map(([key, value]) => {
        return { key: key, value: value, show: false };
      }),
      rules: {
        key: Validators.Required.Text,
        value: Validators.Required.Text,
      },
    };
  },
  watch: {
    viewModels: {
      handler() {
        this.model.credentials = Object.assign(
          {},
          ...this.viewModels.map((x) => ({ [x.key]: x.value }))
        );
      },
      deep: true,
    },
  },
  methods: {
    add() {
      let item = { key: "", value: "", show: false };
      this.viewModels.push(item);
    },
    remove(index) {
      this.viewModels.splice(index, 1);
    },
  },
};
</script>
