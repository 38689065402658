<template>
    <v-navigation-drawer
        style="width: 56px; margin-top: 64px; height: calc(100vh - 64px)"
        fixed
        permanent
    >
        <menu-comp
            icon="mdi-format-list-checkbox"
            dataCy="data-providers"
            @icon-clicked="dataProviders()"
            :path="['/', '/dataprovider*']"
        >
            <v-list-item-group color="primary">
                <v-list-item> Data Providers </v-list-item>
            </v-list-item-group>
        </menu-comp>
        <menu-comp
            icon="mdi-lock"
            dataCy="certificates"
            @icon-clicked="certficates()"
            :path="['/manage-certificates*']"
        >
            <v-list-item-group color="primary">
                <v-list-item> Certificates </v-list-item>
            </v-list-item-group>
        </menu-comp>
        <menu-comp
            icon="mdi-information"
            dataCy="invocation-details"
            @icon-clicked="invocationDetails()"
            :path="['/invocation-details*']"
        >
            <v-list-item-group color="primary">
                <v-list-item> Invocation Details </v-list-item>
            </v-list-item-group>
        </menu-comp>
    </v-navigation-drawer>
</template>

<style>
.v-navigation-drawer__content {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
</style>

<script>
import menuComp from "@/components/menu.vue";
export default {
    components: {
        menuComp,
    },
    name: "nav-menu",
    methods: {
        dataProviders() {
            if (this.$route.path !== "/") {
                this.$router.push("/");
            }
        },
        certficates() {
            if (this.$route.path !== "/manage-certificates") {
                this.$router.push("/manage-certificates");
            }
        },
        invocationDetails() {
            if (this.$route.path !== "/invocation-details") {
                this.$router.push("/invocation-details");
            }
        },
    },
};
</script>
