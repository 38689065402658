<template>
  <v-card>
    <v-card-text>
      <h4>{{ title }}</h4>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <td>Header</td>
              <td>Value</td>
              <td>Actions</td>
              <td>
                <tooltip message="Add header" v-if="!readonly">
                  <v-icon data-cy="headeradd" @click="add()">mdi-plus</v-icon>
                </tooltip>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in headers" :key="i">
              <td>
                <v-text-field
                  data-cy="headername"
                  v-model="item.key"
                  :rules="rules.key"
                  :readonly="readonly"
                  @input="updateHeaders"
                ></v-text-field>
              </td>
              <td>
                <encrypted-input
                  data-cy="headervalue"
                  v-model="item.value"
                  :alwaysShowEncryptedValue="alwaysShowEncryptedValue"
                  :readonly="readonly"
                  @input="updateHeaders"
                ></encrypted-input>
              </td>
              <td>
                <tooltip message="Delete" v-if="!readonly">
                  <friss-icon
                    data-cy="paramdelete"
                    @click="remove(i)"
                    name="delete"
                  ></friss-icon>
                </tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import tooltip from "@/components/tooltip.vue";
import { Validators } from "@/helpers";

export default {
  name: "headers",
  components: {
    tooltip,
  },
  props: {
    customHeaders: {},
    readonly: Boolean,
    title: String,
    alwaysShowEncryptedValue: Boolean,
  },
  data: function () {
    return {
      rules: {
        key: Validators.Required.Text,
      },
      headers: [],
    };
  },
  created() {
    this.headers = this.customHeaders;
  },

  watch: {
    customHeaders: function (newVal, oldVal) {
      this.headers = newVal; 
    },
  },
  updated() {
    this.headers = Object.entries(this.customHeaders).map(([key, value]) => ({
      key,
      value,
    }));
  },
  methods: {
    add() {
      let header = { key: "", value: "" };
      this.headers.push(header);
    },
    remove(index) {
      this.headers.splice(index, 1);
      this.updateHeaders();
    },
    updateHeaders: function () {
      this.$emit("updateHeaders", this.headers);
    },
  },
};
</script>
